// import i18n from '@/i18n/index'

export default {
  tableDataItem: [
    { prop: 'id', label: '', Object: 'value', width: '120' },
    { prop: 'user', label: '', Object: 'value', width: '150' },
    { prop: 'charge_session', label: '', Object: 'value', width: '200' },
    { prop: 'total_amount', label: '', Object: 'value', width: '120' },
    { prop: 'payment_method', label: '', Object: 'value', width: '180' },
    { prop: 'status', label: '', Object: 'value', width: '150' }
  ]
}
